import './globals.css'
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import * as d3 from 'd3'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select"
import { Button } from "./components/ui/button"
import { Alert, AlertDescription, AlertTitle } from "./components/ui/alert"
import { AlertCircle } from "lucide-react"
import { Checkbox } from "./components/ui/checkbox"
import { Label } from "./components/ui/label"
import html2canvas from 'html2canvas'
import { Analytics } from "@vercel/analytics/react"

const countryEmojis: { [key: string]: string } = {
  USA: "🇺🇸",
  France: "🇫🇷",
  Canada: "🇨🇦"
};

interface DataPoint {
  date: Date
  value: number
  region: string
  country: string
}

const defaultState = {
  selectedCountry: "USA",
  selectedRegions: ["National"],
  selectedPeriod: "all",
  selectedScale: "all_results",
  chartType: 'bar' as const,
  showMovingAverage: false,
}

export default function Component() {
  const [data, setData] = useState<DataPoint[]>([])
  const [selectedCountry, setSelectedCountry] = useState<string>(defaultState.selectedCountry)
  const [selectedRegions, setSelectedRegions] = useState<string[]>(defaultState.selectedRegions)
  const [selectedPeriod, setSelectedPeriod] = useState<string>(defaultState.selectedPeriod)
  const [selectedScale, setSelectedScale] = useState<string>(defaultState.selectedScale)
  const [chartType, setChartType] = useState<'line' | 'bar'>(defaultState.chartType)
  const [showMovingAverage, setShowMovingAverage] = useState<boolean>(defaultState.showMovingAverage)
  const [error, setError] = useState<string | null>(null)
  const chartRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const loadData = async () => {
      try {
        console.log("Starting data load...");
        const [usaData, franceData, canadaData] = await Promise.all([
          d3.csv("/USA-2-o6Mn1c0FDMqEj2iDDZLAt6w8lLOfhB.csv"),
          d3.csv("/France-CIj95FPtTAjiZIsmvBPSpePCu83pjE.csv"),
          d3.csv("/Canada_filtered.csv")
        ]);

        console.log("Raw USA Data:", usaData);
        console.log("Raw France Data:", franceData);
        console.log("Raw Canada Data:", canadaData);

        if (!usaData.length || !franceData.length || !canadaData.length) {
          throw new Error("One or more CSV files are empty or couldn't be read");
        }

        const parsedData: DataPoint[] = [];

        // Parse USA data
        const usaRegions = ["Midwest", "National", "Northeast", "South", "West"];
        usaData.forEach((row: d3.DSVRowString) => {
          const date = new Date(row.date);
          usaRegions.forEach(region => {
            const value = row[region];
            if (date && value && value !== 'NA') {
              parsedData.push({
                date,
                value: parseFloat(value),
                region,
                country: "USA"
              });
            }
          });
        });

        // Parse France data
        const franceParseDate = d3.timeParse("%Y-S%W");
        franceData.forEach((row: d3.DSVRowString) => {
          const date = franceParseDate(row.semaine);
          if (date) {
            Object.entries(row).forEach(([region, value]) => {
              if (region !== 'semaine' && value && value !== 'NA') {
                parsedData.push({
                  date,
                  value: parseFloat(value),
                  region: region === "Unknown Region" ? "National" : region,
                  country: "France"
                });
              }
            });
          }
        });

        // Parse Canada data
        const canadaRegions = Array.from(new Set(canadaData.map(row => row.province)));
        canadaRegions.forEach(region => {
          const regionData = canadaData.filter(row => row.province === region);
          regionData.sort((a, b) => new Date(a.weekstart).getTime() - new Date(b.weekstart).getTime());
          
          regionData.forEach((row) => {
            const date = new Date(row.weekstart);
            if (date && row.w_avg && row.w_avg !== 'NA') {
              parsedData.push({
                date,
                value: parseFloat(row.w_avg),
                region: region === "Unknown Region" ? "National" : region,
                country: "Canada"
              });
            }
          });
        });

        // Aggregate data to ensure one datapoint per region per date
        const aggregatedData = d3.rollup(
          parsedData,
          v => d3.mean(v, d => d.value) as number,
          d => d.country,
          d => d.region,
          d => d.date.toISOString().split('T')[0]
        );

        const finalData: DataPoint[] = [];
        aggregatedData.forEach((countryData, country) => {
          countryData.forEach((regionData, region) => {
            regionData.forEach((value, dateString) => {
              finalData.push({
                date: new Date(dateString),
                value,
                region,
                country
              });
            });
          });
        });

        console.log("Aggregated Data:", finalData);
        console.log("USA Regions:", usaRegions);
        console.log("France Regions:", Array.from(new Set(finalData.filter(d => d.country === "France").map(d => d.region))));
        console.log("Canada Regions:", canadaRegions);

        if (!finalData.length) {
          throw new Error("No data could be parsed from the CSV files");
        }

        setData(finalData);
        setError(null);
      } catch (err) {
        console.error("Error loading data:", err);
        setError(`Failed to load data: ${err instanceof Error ? err.message : String(err)}`);
      }
    };

    loadData();
  }, []);

  const filteredData = useMemo(() => {
    let filtered = data.filter(d => 
      d.country === selectedCountry && 
      selectedRegions.includes(d.region)
    );

    if (selectedScale !== "all_results") {
      const now = new Date();
      const cutoffDate = new Date(now);
      
      switch (selectedScale) {
        case "1_year":
          cutoffDate.setFullYear(now.getFullYear() - 1);
          break;
        case "6_months":
          cutoffDate.setMonth(now.getMonth() - 6);
          break;
        case "45_days":
          cutoffDate.setDate(now.getDate() - 45);
          break;
      }

      filtered = filtered.filter(d => d.date >= cutoffDate);
    }

    return filtered;
  }, [data, selectedCountry, selectedRegions, selectedScale])

  const regions = useMemo(() => {
    const countryData = data.filter(d => d.country === selectedCountry);
    const uniqueRegions = Array.from(new Set(countryData.map(d => d.region)))
      .filter(region => region && region.trim() !== "")
      .sort();
    console.log(`Regions for ${selectedCountry}:`, uniqueRegions);
    return uniqueRegions;
  }, [data, selectedCountry])

  useEffect(() => {
    setSelectedRegions(prevRegions => {
      if (selectedCountry === "USA") {
        if (prevRegions.length === 0 || !prevRegions.every(region => regions.includes(region))) {
          return ["National"];
        }
      } else if (regions.length > 0 && !regions.includes(prevRegions[0])) {
        return [regions[0]];
      }
      return prevRegions;
    });
  }, [selectedCountry, regions]);

  const calculateMovingAverage = (data: DataPoint[], windowSize: number) => {
    return data.map((d, i, arr) => {
      const window = arr.slice(Math.max(0, i - windowSize + 1), i + 1);
      const sum = window.reduce((acc, curr) => acc + curr.value, 0);
      return {
        ...d,
        value: sum / window.length
      };
    });
  };

  const renderChart = useCallback(() => {
    if (!chartRef.current || !containerRef.current || filteredData.length === 0) return;

    const svg = d3.select(chartRef.current);
    svg.selectAll("*").remove();

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;

    const margin = { top: 40, right: 80, bottom: 60, left: 60 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    svg.attr("width", containerWidth).attr("height", containerHeight);

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime()
      .range([0, width]);

    const y = d3.scaleLinear()
      .range([height, 0]);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const line = d3.line<DataPoint>()
      .x(d => x(d.date))
      .y(d => y(d.value));

    const area = d3.area<DataPoint>()
      .x(d => x(d.date))
      .y0(height)
      .y1(d => y(d.value));

    x.domain(d3.extent(filteredData, d => d.date) as [Date, Date]);
    y.domain([0, d3.max(filteredData, d => d.value) as number]);

    // Add gradient definition
    const gradient = svg.append("defs")
      .append("linearGradient")
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0).attr("y1", y(0))
      .attr("x2", 0).attr("y2", y(d3.max(filteredData, d => d.value) as number));

    gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "lightblue");

    gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "red");

    // Add X axis
    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x)
        .ticks(width > 600 ? 10 : 5)
        .tickFormat((d) => d3.timeFormat("%b %Y")(d as Date)))
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

    // Add Y axis
    g.append("g")
      .call(d3.axisLeft(y)
        .ticks(5)
        .tickFormat(d => d3.format(".2s")(d as number)));

    // Create a tooltip
    const tooltip = d3.select(containerRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "rgba(255, 255, 255, 0.9)")
      .style("border", "1px solid #ddd")
      .style("border-radius", "4px")
      .style("padding", "8px")
      .style("pointer-events", "none")
      .style("font-size", "12px")
      .style("font-family", "sans-serif");

    const formatTooltipValue = (value: number) => {
      return selectedCountry === "France" && value >= 100 ? d3.format(".0f")(value) : d3.format(".1f")(value);
    };

    const renderRegionData = (regionData: DataPoint[], region: string) => {
      if (chartType === 'line') {
        // Sort the data by date
        regionData.sort((a, b) => a.date.getTime() - b.date.getTime());

        // Add the gradient area
        g.append("path")
          .datum(regionData)
          .attr("fill", "url(#area-gradient)")
          .attr("opacity", 0.3)
          .attr("d", area);

        // Add the line
        g.append("path")
          .datum(regionData)
          .attr("fill", "none")
          .attr("stroke", color(region))
          .attr("stroke-width", 2)
          .attr("d", line);

        // Add dots
        g.selectAll(`.dot-${region.replace(/[^a-zA-Z0-9]/g, '_')}`)
          .data(regionData)
          .enter().append("circle")
            .attr("class", `dot-${region.replace(/[^a-zA-Z0-9]/g, '_')}`)
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.value))
            .attr("r", 2)
            .attr("fill", color(region));
      } else {
        // Add bars
        const barWidth = width / (filteredData.length * (selectedCountry === "USA" ? selectedRegions.length : 1));
        g.selectAll(`.bar-${region.replace(/[^a-zA-Z0-9]/g, '_')}`)
          .data(regionData)
          .enter().append("rect")
            .attr("class", `bar-${region.replace(/[^a-zA-Z0-9]/g, '_')}`)
            .attr("x", (d, i) => x(d.date) + (barWidth * (selectedCountry === "USA" ? selectedRegions.indexOf(region) : 0)))
            .attr("y", d => y(d.value))
            .attr("width", barWidth)
            .attr("height", d => height - y(d.value))
            .attr("fill", color(region));
      }

      if (showMovingAverage) {
        const movingAverageData = calculateMovingAverage(regionData, 7);
        g.append("path")
          .datum(movingAverageData)
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "5,5")
          .attr("d", line);
      }
    };

    selectedRegions.forEach(region => {
      const regionData = filteredData.filter(d => d.region === region);
      renderRegionData(regionData, region);
    });

    // Create an overlay to capture mouse events
    g.append("rect")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mouseover", () => {
        tooltip.style("opacity", 1);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      })
      
      .on("mousemove", (event) => {
        const [xPos] = d3.pointer(event);
        const x0 = x.invert(xPos);
        const bisect = d3.bisector((d: DataPoint) => d.date).left;

        let tooltipContent = `<strong>${d3.timeFormat("%b %d, %Y")(x0)}</strong><br>`;
        selectedRegions.forEach(region => {
          const regionData = filteredData.filter(d => d.region === region);
          const i = bisect(regionData, x0, 1);
          const d0 = regionData[i - 1];
          const d1 = regionData[i];
          if (d0 && d1) {
            const d = x0.valueOf() - d0.date.valueOf() > d1.date.valueOf() - x0.valueOf() ? d1 : d0;
            tooltipContent += `${region}: ${formatTooltipValue(d.value)} gene copies/mL<br>`;
          }
        });

        tooltip.html(tooltipContent)
          .style("left", `${event.clientX}px`)
          .style("top", `${event.clientY - 40}px`);
      });

    // Add legend
    const legend = svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(selectedRegions)
      .enter().append("g")
      .attr("transform", (d, i) => `translate(0,${i * 20})`);

    legend.append("rect")
      .attr("x", containerWidth - 19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", color);

    legend.append("text")
      .attr("x", containerWidth - 24)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text(d => d);

    // Add title
    svg.append("text")
      .attr("x", containerWidth / 2)
      .attr("y", margin.top / 2)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(`COVID-19 Data: ${countryEmojis[selectedCountry]} ${selectedCountry}`);

    // Add X axis label
    svg.append("text")
      .attr("x", containerWidth / 2)
      .attr("y", containerHeight - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text("Date");

    // Add Y axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -(containerHeight / 2))
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text("Viral Load (gene copies/mL)");
  }, [filteredData, chartType, selectedCountry, selectedRegions, showMovingAverage]);

  useEffect(() => {
    renderChart();
    window.addEventListener('resize', renderChart);
    return () => window.removeEventListener('resize', renderChart);
  }, [renderChart]);

  const handleRegionChange = (region: string) => {
    setSelectedRegions(prevRegions => {
      if (selectedCountry === "USA") {
        return prevRegions.includes(region)
          ? prevRegions.filter(r => r !== region)
          : [...prevRegions, region];
      } else {
        return [region];
      }
    });
  };

  const handleReset = () => {
    setSelectedCountry(defaultState.selectedCountry);
    setSelectedRegions(defaultState.selectedRegions);
    setSelectedPeriod(defaultState.selectedPeriod);
    setSelectedScale(defaultState.selectedScale);
    setChartType(defaultState.chartType);
    setShowMovingAverage(defaultState.showMovingAverage);
  };

  const exportToPNG = async () => {
    if (containerRef.current) {
      const canvas = await html2canvas(containerRef.current)
      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = `covid19_chart_${selectedCountry}.png`
          link.click()
          URL.revokeObjectURL(url)
        }
      })
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <Card className="w-full md:w-64 md:h-full rounded-none md:border-r">
        <CardHeader>
          <CardTitle>Controls</CardTitle>
          <CardDescription>Adjust visualization parameters</CardDescription>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="countrySelect" className="text-sm font-medium">Country</label>
              <Select
                value={selectedCountry}
                onValueChange={(value) => {
                  setSelectedCountry(value);
                  setSelectedRegions([]);
                }}
              >
                <SelectTrigger id="countrySelect">
                  <SelectValue placeholder="Select country" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="USA">🇺🇸 USA</SelectItem>
                  <SelectItem value="France">🇫🇷 France</SelectItem>
                  <SelectItem value="Canada">🇨🇦 Canada</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">Region</label>
              {selectedCountry === "USA" ? (
                <div className="space-y-2 max-h-40 overflow-y-auto">
                  {regions.map(region => (
                    <div key={region} className="flex items-center space-x-2">
                      <Checkbox
                        id={region}
                        checked={selectedRegions.includes(region)}
                        onCheckedChange={() => handleRegionChange(region)}
                      />
                      <Label htmlFor={region}>{region}</Label>
                    </div>
                  ))}
                </div>
              ) : (
                <Select
                  value={selectedRegions[0]}
                  onValueChange={(value) => setSelectedRegions([value])}
                >
                  <SelectTrigger id="regionSelect">
                    <SelectValue placeholder="Select region" />
                  </SelectTrigger>
                  <SelectContent className="max-h-[200px] overflow-y-auto">
                    {regions.map(region => (
                      <SelectItem key={region} value={region}>
                        {region}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
            <div className="space-y-2">
              <label htmlFor="scaleSelect" className="text-sm font-medium">Scale</label>
              <Select
                value={selectedScale}
                onValueChange={setSelectedScale}
              >
                <SelectTrigger id="scaleSelect">
                  <SelectValue placeholder="Select scale" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all_results">All Results</SelectItem>
                  <SelectItem value="1_year">1 Year</SelectItem>
                  <SelectItem value="6_months">6 Months</SelectItem>
                  <SelectItem value="45_days">45 Days</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">Chart Type</label>
              <div className="flex space-x-2">
                <Button 
                  onClick={() => setChartType('bar')} 
                  variant={chartType === 'bar' ? 'default' : 'outline'}
                >
                  Bar
                </Button>
                <Button 
                  onClick={() => setChartType('line')} 
                  variant={chartType === 'line' ? 'default' : 'outline'}
                >
                  Line
                </Button>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="movingAverage"
                checked={showMovingAverage}
                onCheckedChange={(checked: boolean) => setShowMovingAverage(checked)}
              />
              <Label htmlFor="movingAverage">Show 7-day Moving Average</Label>
            </div>
            <Button onClick={handleReset} variant="outline" className="w-full">
              Reset to Default
            </Button>
            <Button onClick={exportToPNG} variant="outline" className="w-full">
              Export to PNG
            </Button>
            <div className="mt-6 pt-6 border-t flex items-center justify-center space-x-2 text-sm text-muted-foreground">
              <img
                src="/x-logo.svg"
                alt="X Logo"
                width={16}
                height={16}
              />
              <a
                href="https://twitter.com/jmdurandeau"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                by @jmdurandeau
              </a>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex-1 p-4 md:p-6">
        <Card className="w-full h-full">
          <CardHeader>
            <CardTitle className="text-lg md:text-xl">COVID-19 Wastewater Data Visualization</CardTitle>
            <CardDescription>
              {`Displaying data for ${countryEmojis[selectedCountry]} ${selectedCountry}`}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {error ? (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            ) : (
              <div ref={containerRef} className="w-full h-[300px] md:h-[500px]">
                <svg ref={chartRef} width="100%" height="100%"></svg>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}